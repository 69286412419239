@mixin make-grid($name) {

    @for $i from 1 through $grid-columns {
        .grid__col--#{$name}-#{$i} {
            order: auto;
            flex-basis: 100% / $grid-columns * $i;
            max-width: 100% / $grid-columns * $i;
        }
    }

    @for $i from 1 through $grid-columns {
        .grid__col--offset-#{$name}-#{$i} {
            margin-left: 100% / $grid-columns * $i;
        }
    }

    .grid__col--#{$name} {
        flex-grow: 0;
        flex-basis: auto;
        width: auto;
        max-width: 100%;
    }

    .grid__col--top-#{$name} {
        align-self: flex-start;
        vertical-align: top;
    }

    .grid__col--middle-#{$name} {
        align-self: center;
        vertical-align: middle;
    }

    .grid__col--bottom-#{$name} {
        align-self: flex-end;
        vertical-align: bottom;
    }

    .grid--reverse-#{$name} {
        flex-direction: row-reverse;
    }

    .grid--start-#{$name} {
        justify-content: flex-start;
        text-align: left;
    }

    .grid--center-#{$name} {
        justify-content: center;
        text-align: center;
    }

    .grid--end-#{$name} {
        justify-content: flex-end;
        text-align: right;
    }

    .grid--top-#{$name} {
        align-items: flex-start;

        > [class^='grid__col--'],
        > [class*=' grid__col--'] {
            vertical-align: top;
        }
    }

    .grid--middle-#{$name} {
        align-items: center;

        > [class^='grid__col--'],
        > [class*=' grid__col--'] {
            vertical-align: middle;
        }
    }

    .grid--bottom-#{$name} {
        align-items: flex-end;

        > [class^='grid__col--'],
        > [class*=' grid__col--'] {
            vertical-align: bottom;
        }
    }

    .grid--around-#{$name} {
        justify-content: space-around;
    }

    .grid--between-#{$name} {
        justify-content: space-between;
    }

    .grid__col--original-#{$name} {
        order: 0;
    }

    .grid__col--first-#{$name} {
        order: -1;
    }

    .grid__col--last-#{$name} {
        order: 1;
    }
}

.grid {
    letter-spacing: -.65em;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $grid-gutter * .5 * -1;
    margin-left: $grid-gutter * .5 * -1;
    margin-bottom: $grid-gutter * -1;
}

.grid--equalheight {
    > [class^='grid__col--'],
    > [class*=' grid__col--'] {
        display: flex;
    }
}

.grid--no-gutter {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;

    > [class^='grid__col--'],
    > [class*=' grid__col--'] {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
    }
}

.grid--no-horizontal-gutter {
    margin-right: 0;
    margin-left: 0;

    > [class^='grid__col--'],
    > [class*=' grid__col--'] {
        padding-left: 0;
        padding-right: 0;
    }
}

.grid--no-vertical-gutter {
    margin-bottom: 0;

    > [class^='grid__col--'],
    > [class*=' grid__col--'] {
        margin-bottom: 0;
    }
}

[class^='grid__col--'],
[class*=' grid__col--'] {
    letter-spacing: 0;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    flex: 0 0 auto;
    padding-right: $grid-gutter * .5;
    padding-left: $grid-gutter * .5;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: $grid-gutter;
    outline: none;
}

.grid__col--no-vertical-gutter {
    margin-bottom: 0;
}

@include make-grid(xs);

@include bp(sm-min) {

    @include make-grid(sm);
}

@include bp(md-min) {

    @include make-grid(md);
}

@include bp(lg-min) {

    @include make-grid(lg);
}
