$textfield-default: #ffffff;
$textfield-border: #ffffff;
$textfield-error: #ff0000;
$textfield-valid: #00cc66;

@mixin textfield-animation($duration: .2s) {
    transition-duration: $duration;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.form-textfield {
    position: relative;
    font-size: 16px;
    width: 100%;
    max-width: 100%;

    &.is-disabled {
        color: $textfield-border;
    }

    &.is-focused {
        z-index: 2;
    }
}

.form-textfield__inner {
    position: relative;
    padding: 14px 0 0;
}

.form-textfield__input {
    border: none;
    border-bottom: 2px solid $textfield-border;
    border-radius: 0;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    height: 32px;
    width: 100%;
    background: none;
    text-align: left;
    color: inherit;
    padding-right: 20px;

    &--center {
        text-align: center;
        padding-right: 0;
        padding-left: 10px;
    }

    .form-textfield.is-focused & {
        outline: none;
    }

    .form-textfield.is-invalid & {
        border-color: $textfield-error;
    }

    .form-textfield.is-disabled & {
        background-color: transparent;
        border-bottom: 2px solid $textfield-border;
        color: $textfield-border;
        -webkit-text-fill-color: $textfield-border;
        outline: none;
    }

    &::-ms-clear {
        display: none;
    }

    &:focus {
        outline: none;

        + .form-textfield__label {
            top: 0;
            font-size: 12px;

            &:after {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &--icon {
        padding-right: 20px;
    }
}

span.form-textfield__input {
    padding-top: 5px;
    border-color: transparent;
    height: auto;
}

.form-textfield__label {
    bottom: 0;
    color: $textfield-default;
    font-size: 12px;
    left: 0;
    right: 0;
    pointer-events: none;
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;

    .form-textfield.is-dirty & {
        color: $textfield-border;
    }

    .form-textfield--floating-label & {
        top: 16px;
        font-size: 16px;
        @include textfield-animation();
    }

    .form-textfield--floating-label.is-focused &,
    .form-textfield--floating-label.is-dirty & {
        color: $textfield-default;
        font-size: 12px;
        top: 0;
        visibility: visible;
    }

    .form-textfield--floating-label.is-dirty & {
        color: $textfield-border;
    }

    .form-textfield--floating-label.is-dirty.is-focused & {
        color: $textfield-default;
    }

    .form-textfield--floating-label.is-invalid & {
        color: $textfield-error;
    }

    .form-textfield--floating-label.is-invalid.is-focused & {
        color: $textfield-error;
    }

    &:after {
        background-color: $textfield-default;
        bottom: 0;
        content: '';
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        @include textfield-animation();
        visibility: hidden;
        width: 100%;
    }

    .form-textfield.is-focused &:after {
        visibility: visible;
        opacity: 1;
    }

    .form-textfield.is-invalid &:after {
        background-color: $textfield-error;
    }
}
