.counter {
    // todo
}

.counter__text {
    font-size: 32px;

    .counter--large & {
        font-size: 52px;
    }
}
