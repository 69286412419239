.text {
    > * + * {
        margin-top: 1em;
    }
}

h1,
.h1 {
    font-size: 40px;
    line-height: 1;
    font-family: $font-heading;

    @include bp(md-min) {
        font-size: 56px;
    }
}

h2,
.h2 {
    font-size: 2.5em;
    line-height: 1;
    font-family: $font-heading;

    @include bp(md-min) {
        font-size: 4em;
    }
}

h3,
.h3 {
    font-size: 2em;
    line-height: 1;

    @include bp(sm-min) {
        font-size: 3em;
    }
}

h4,
.h4 {
    font-size: 1.5em;

    @include bp(sm-min) {
        font-size: 2em;
    }
}

i,
em,
.italic {
    font-style: italic;
}

b,
strong,
.bold {
    font-weight: 700;
}

a,
.link {
    color: $color-primary;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.link-white {
    color: $color-white;
    font-weight: 700;
}
