.header {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 11px 18px;

    @include bp(sm-min) {
        position: static;
        padding: 30px 60px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (orientation:landscape) and (min-height: 700px) {
        padding: 50px 60px;
    }

    @media screen and (orientation:landscape) and (min-height: 800px) {
        padding: 80px 60px;
    }
}

.header__title {
    flex: 1 1 100%;
    display: none;
    text-align: center;

    @include bp(sm-min) {
        display: block;
    }
}

.header__logo {
    width: 114px;
    flex: 1 0 114px;
    max-width: 114px;
    position: absolute;
    left: 18px;
    bottom: 15px;

    @include bp(sm-min) {
        position: static;
        width: 140px;
        flex: 1 0 140px;
        max-width: 140px;
    }
}

.header__counter {
    text-align: center;

    @include bp(sm-min) {
        text-align: right;
        flex: 1 0 140px;
        position: relative;
    }
}

.header__counter-results {
    display: none;

    @include bp(sm-min) {
        display: block;
        position: absolute;
        bottom: -30px;
        right: 0;
    }
}
