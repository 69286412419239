.orientation-dialog {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background-color: $color-tertiary;
    color: $color-white;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    @media only screen and (orientation:portrait) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    @include bp(sm-min) {
        display: none !important;
    }
}

.orientation-dialog__inner {
    flex: 1 1 100%;
    padding: 32px;
}

.orientation-dialog__text {
    margin-top: 40px;
    font-size: 30px;
    line-height: 1.3;
}
