.image__img {
    &.lazyload,
    &.lazyloading {
        opacity: 0;
        transition: opacity $transition-duration $transition-easing;
    }

    &.lazyloaded {
        opacity: 1;
        transition: opacity $transition-duration $transition-easing;
    }

    .image--fluid & {
        max-width: 100%;
        height: auto;
    }
}
