.table {
    // todo
}

.table__data {
    width: 100%;
    color: $color-dgray;
}

.table__data-row {
    border-bottom: 1px solid #e9e8e8;
    text-align: left;

    &.has-highlight td {
        animation-name: showPlace;
        animation-duration: 1000ms;
        animation-fill-mode: forwards;
        animation-delay: 1500ms;
        background-color: #fffad4;
    }

    td {
        padding: 16px 8px 12px;
    }
}

@keyframes showPlace {
    from {
        background-color: #fffad4;
    }

    to {
        background-color: transparent;
    }
}
