.dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    align-items: flex-start;
    justify-content: center;
    visibility: hidden;
    display: flex;
    transition: visibility 600ms cubic-bezier(.19, 1, .22, 1);
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;

    @include bp(sm-min) {
        padding: 0;
        align-items: center;
    }

    &--intro {
        padding: 0;
        align-items: center;
        background-color: $color-white;

        @include bp(sm-min) {
            background-color: transparent;
        }
    }

    &.is-active {
        visibility: visible;
    }
}

.dialog__inner {
    width: 100%;
    max-width: 574px;
    display: inline-block;
    color: $color-white;
    background: $color-primary;
    transform: translate3d(0,200px,0);
    transition: all 600ms cubic-bezier(.19, 1, .22, 1);
    opacity: 0;
    padding: 60px;
    text-align: center;
    box-shadow: 0 24px 33px 5px rgba(0,0,0,.2);
    -webkit-font-smoothing: antialiased;
    overflow: hidden;

    &--results {
        padding-bottom: 112px;
    }

    .dialog.is-active & {
        transform: translate3d(0,0,0);
        opacity: 1;
    }

    .dialog--intro & {
        background-color: $color-white;
        max-width: none;
        color: $color-dgray;
        box-shadow: none;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @include bp(sm-min) {
            height: auto;
            display: block;
            max-width: 574px;
            color: $color-white;
            background: $color-primary;
            box-shadow: 0 24px 33px 5px rgba(0,0,0,.2);
        }
    }
}

.dialog__title {
    margin-bottom: 32px;

    @include bp(sm-min) {
        margin-bottom: 50px;
    }

    .dialog--intro & {
        flex: 1 1 100%;
        margin-bottom: 0;
        margin-top: 32px;

        @include bp(sm-min) {
            display: none;
        }
    }
}

.dialog__form {
    max-width: 280px;
    margin: 50px auto 0;
    z-index: 1;
    position: relative;
}

.dialog__form-row {
    & + & {
        margin-top: 22px;
    }
}

.dialog__results {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate3d(0,100%,0);
    margin-top: -46px;
    transition: all 600ms cubic-bezier(.19, 1, .22, 1);
    z-index: -1;

    @include bp(sm-min) {
        margin-top: -112px;
    }

    &.is-visible {
        margin-top: 0;
        z-index: 2;
        transform: translate3d(0,0,0);
    }
}

.dialog__results-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    height: 46px;
    flex: 0 0 46px;

    @include bp(sm-min) {
        height: 112px;
        flex: 0 0 112px;
        background: $color-primary;
    }
}

.dialog__results-label {
    color: $color-primary;
    position: relative;
    padding-right: 18px;

    @include bp(sm-min) {
        color: $color-white;
    }

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        right: 0;
        border-left: 1px solid currentColor;
        border-bottom: 1px solid currentColor;
        display: block;
        width: 8px;
        height: 8px;
        transform: rotate(-45deg);
    }

    .dialog__results.is-visible &:before {
        transform: rotate(135deg);
        top: 11px;
    }
}

.dialog__results-container {
    flex: 1 1 100%;
    background-color: $color-white;
    overflow-y: auto;
    padding: 10px 60px 0;

    @include bp(sm-min) {
        padding: 40px 60px 20px;
    }
}
