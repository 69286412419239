.game {
    position: absolute;
    bottom: 56px;
    top: 0;
    left: 0;
    right: 0;

    @include bp(sm-min) {
        top: 120px;
        left: 40px;
        bottom: 40px;
        right: 40px;
    }

    @media screen and (orientation:landscape) and (min-height: 700px) {
        top: 160px;
    }

    @media screen and (orientation:landscape) and (min-height: 800px) {
        top: 220px;
        left: 60px;
        bottom: 60px;
        right: 60px;
    }
}

.game__canvas {
    max-width: 100%;
    margin: 3px auto;
    display: block;
}

.game__controller {
    width: 92px;
    height: 92px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    box-shadow: 0 10px 14px 0 rgba(0,0,0,.16);
    border-radius: 50%;
    background-color: $color-primary;

    @include bp(lg-min) {
        display: none;
    }
}

.game__controller-button {
    position: absolute;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 12px 7.5px;
        border-color: transparent transparent #ffffff transparent;
        display: inline-block;
        vertical-align: top;
    }

    &--up {
        top: 0;
        padding-top: 5px;
        left: 50%;
        width: 40px;
        margin-left: -20px;
    }

    &--left {
        left: 0;
        top: 50%;
        padding-top: 5px;
        margin-top: -16px;
        width: 40px;
        transform: rotate(-90deg);
    }

    &--right {
        right: 0;
        padding-top: 5px;
        top: 50%;
        width: 40px;
        margin-top: -16px;
        transform: rotate(90deg);
    }

    &--down {
        bottom: 0;
        padding-top: 5px;
        width: 40px;
        left: 50%;
        margin-left: -20px;
        transform: rotate(-180deg);
    }
}
