.button {
    display: inline-block;
    padding: 0 50px;
    height: 50px;

    background: $button-default-background;
    border: 1px solid $button-default-background;
    color: $button-default-color;
    font-size: 16px;
    line-height: 48px;
    border-radius: 0;
    text-decoration: none;
    letter-spacing: 1px;
    -webkit-appearance: none;

    cursor: pointer;
    transition: all 250ms ease-out;

    @include bp(sm-min) {
        height: 56px;
        font-size: 18px;
        line-height: 54px;
    }

    &:hover {
        background: $button-default-color;
        border: 1px solid $button-default-color;
        color: $button-default-background;
        text-decoration: none;
    }

    &--secondary {
        background: $button-secondary-background;
        border: 1px solid $button-secondary-background;
        color: $button-secondary-color;

        &:hover {
            background: $button-secondary-background-hover;
            border: 1px solid $button-secondary-background-hover;
            color: $button-secondary-color;
        }
    }

    &--tertiary {
        background: $button-tertiary-background;
        border: 1px solid $button-tertiary-background;
        color: $button-tertiary-color;

        &:hover {
            background: $button-tertiary-background-hover;
            border: 1px solid $button-tertiary-background-hover;
            color: $button-tertiary-color;
        }
    }

    &--w-shadow {
        box-shadow: 0 10px 14px rgba($color-dgray,.16);

        @include bp(sm-min) {
            box-shadow: 0 10px 14px rgba($color-dgray,.16);
        }

        &:hover {
            transform: translateY(2px);
            box-shadow: 0 8px 14px rgba($color-dgray,.16);

            @include bp(sm-min) {
                box-shadow: 0 2px 14px rgba($color-dgray,.16);
            }
        }
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--link {
        background: inherit;
        border-color: transparent;
        color: $button-link-color;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}
